.blog-thumbnail__title {
  @extend %gutter-m-top-0x;
  @extend %gutter-m-bottom-05x;
  display: block;
  font-size: 20px;
}

.blog-thumbnail__description {
  display: block;
}
