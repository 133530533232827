$tbnlgrid-col--width: (
  'xs': 100%,
  'sm': 50%,
  'md': 25%,
  'lg': 25%,
);

$tbnl-btn--themes: (
  'default': (
    'background-color': rgb(255, 255, 255),
    'background-color--hover': rgb(88, 88, 90),
    'color': rgb(88, 88, 90),
    'color--hover': rgb(255, 255, 255),
    'border-color': currentColor,
    'border-color--hover': rgb(88, 88, 90),
  ),
  'info': (
    'background-color': rgb(46, 59, 90),
    'background-color--hover': rgb(41, 54, 85),
    'color': rgb(255, 255, 255),
    'color--hover': rgb(255, 255, 255),
    'border-color': rgb(41, 54, 85),
    'border-color--hover': rgb(36, 49, 80),
  ),
  'success': (
    'background-color': pick-color('green'),
    'background-color--hover': darken(pick-color('green'), 10%),
    'color': pick-color('white'),
    'color--hover': pick-color('white'),
    'border-color': transparent,
    'border-color--hover': transparent,
  ),
);
