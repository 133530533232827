@import 'partials/parameters';
@import 'partials/str-replace';
@import 'partials/unit-functions';
@import 'partials/selectors-generator';
@import 'partials/rules-generator';
@import 'partials/get-gutter';

@mixin gutter-helper--generator($property: 'margin', $direction: '', $multiplier: 1, $breakpoint: '') {
    #{gh-get-selector--complete($property, $direction, $multiplier, $breakpoint)} {
        @include gh-get-declaration($property, $direction, $multiplier, $breakpoint);
    }
}

$index: 0;
@each $multiplier in $gh-multipliers {
    @each $direction in $gh-directions {
        @each $property in $gh-properties {
            @include gutter-helper--generator($property, $direction, $multiplier, '');
            $index: $index + 1;

            @if $gh-responsive {
                @each $breakpoint-key, $breakpoint-value in $breakpoints {
                    @include media-query($breakpoint-key, 'mobile-first') {
                        @include gutter-helper--generator($property, $direction, $multiplier, $breakpoint-key);
                        $index: $index + 1;
                    }
                }
            }
        }
    }
}
