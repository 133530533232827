// scss-lint:disable ColorVariable
$cstmchckbx--themes: (
  'red': (
    'background-color': pick-color('red'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'orange': (
    'background-color': pick-color('orange'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'pink': (
    'background-color': pick-color('pink'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'violet': (
    'background-color': pick-color('violet'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'green-dark': (
    'background-color': pick-color('green-dark'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'blue': (
    'background-color': pick-color('blue'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'green-light': (
    'background-color': pick-color('green-light'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'brown': (
    'background-color': pick-color('brown'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'violet-fluo': (
    'background-color': pick-color('violet-fluo'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
  'grey': (
    'background-color': pick-color('white-grey-dark'),
    'color': pick-color('white'),
    'border-color': transparent,
  ),
);
// scss-lint:enable ColorVariable

@mixin when-cstmchckbx-checked {
  .custom-checkbox__native:checked ~ & {
    @content;
  }
}

@mixin when-cstmchckbx-theme ($theme) {
  .custom-checkbox--#{$theme} & {
    @content;
  }
}

.custom-checkbox {
  display: block;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox__native {
  display: none;
  visibility: hidden;
}

.custom-checkbox__visual {
  display: inline-block;
  width: $cstmchckbx--size;
  height: $cstmchckbx--size;
  border-width: $cstmchckbx--border-width;
  border-style: solid;
  border-color: $cstmchckbx--border-color;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: $cstmchckbx--color;

  @each $theme, $color in $program--themes {
    @include when-cstmchckbx-theme($theme) {
      color: pick-color('white');
      border-color: transparent;
      background-color: $color;
    }
  }

  &::before {
    @include when-cstmchckbx-checked {
      content: '\f00c';
      font-family: fontAwesome;
      display: inline-block;
      line-height: $cstmchckbx--size - ($cstmchckbx--border-width * 2);
    }
  }
}

.custom-checkbox__label {
  padding-left: $cstmchckbx--inner-padding + $cstmchckbx--size;
  line-height: $cstmchckbx--size;
  display: block;
  color: $cstmchckbx-label--color;
}
