/**
 * map
 * ===
 */

.map {
  height: calc(100vh - #{$header--height + $btn--height-computed});
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  overflow: hidden;

  @include media-query('md', 'mobile-first') {
    height: auto;
  }
}


/**
 * map__mapbox
 * -----------
 */

.map__mapbox {
  height: 100%;

  @include media-query('md', 'mobile-first') {
    height: 600px;
  }
}


/**
 * Mapbox & leaflet rewrites
 * =========================
 */

.marker-title {
  font-weight: 700;
}

.leaflet-container {
  font: inherit;

  // scss-lint:disable SingleLinePerSelector
  h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
    line-height: inherit;
    margin: inherit;
  }
  // scss-lint:enable SingleLinePerSelector
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}

.leaflet-popup-tip-container {
  width: 0;
  height: 0;
  border-top: 10px solid pick-color('white');
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.leaflet-popup-tip {
  display: none;
}

.leaflet-popup-content {
  padding: 0 !important;
  margin: 0;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px;
  padding: 0;
  box-shadow: 0 2px 10px 0 transparentize(pick-color('black'), 0.8);
  overflow: hidden;
}

.leaflet-container a.leaflet-popup-close-button {
  text-indent: 0;
  font-size: 30px;
  width: auto;
  height: auto;
  padding: 0;
  line-height: 39px;
  padding-right: 5px;
  background-image: none;
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}
