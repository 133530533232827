.label {
  @extend %color--white;
  background-color: pick-color('grey-dark');
  color: pick-color('white');
  font-size: 1em;
  padding: 0.5em 0.86em;
  display: inline-block;

  // scss-lint:disable ImportantRule
  &.is-active {
    background-color: pick-color('green');
    cursor: default !important;
  }
  // scss-lint:enable ImportantRule
}

button {
  &.label {
    border-width: 0;
  }
}

// scss-lint:disable NestingDepth
button,
a {
  &.label {
    user-select: none;

    &:hover,
    &:focus,
    &:active {
      background-color: pick-color('black');
    }

    &.is-active {
      &:hover,
      &:focus,
      &:active {
        background-color: pick-color('green');
      }
    }

    &:focus {
      outline-width: 0;
      box-shadow: 0 0 0 0.15em pick-color('grey-light');

      &.is-active {
        box-shadow: none;
      }
    }
  }
}
// scss-lint:enable NestingDepth


@each $theme, $color in $program--themes {
  .label--#{$theme} {
    background-color: $color;

    &.is-active {
      background-color: darken($color, 10%);
    }
  }

  a,
  button {
    &.label--#{$theme} {
      &:hover,
      &:focus,
      &:active {
        background-color: darken($color, 10%);
      }
    }
  }
}
