// We disable QualifyingElement linter flag because the below selector want to
// target only elements <a> and <button>
// scss-lint:disable QualifyingElement
@mixin when-tbnl-hovered {
  a.thumbnail:hover &,
  button.thumbnail:hover & {
    @content;
  }
}

@mixin when-sibling-btn($size, $block) {
  @if $block {
    .thumbnail__btn--#{$size}.thumbnail__btn--block + & {
      @content;
    }
  } @else {
    .thumbnail__btn--#{$size} + & {
      @content;
    }
  }
}
