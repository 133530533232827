// scss-lint:disable IdSelector
.cover--full {
  min-height: calc(100vh - #{$header--height});
  color: pick-color('white');

  @include when-victoire-admin {
    min-height: calc(100vh - #{$header--height + $victoire-interface--height-computed});
  }

  @include media-query('md', 'mobile-first') {
    min-height: calc(100vh - #{$header-md-plus--height-computed});

    @include when-victoire-admin {
      min-height: calc(100vh - #{$header-md-plus--height-computed + $victoire-interface--height-computed});
    }
  }
}

.cover__body--center-center {
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.cover__down {
  @extend %gutter-m-bottom-066x;
  display: inline-block;
  fill: pick-color('white');

  &:hover {
    fill: pick-color('grey');
  }
}
