@import 'parameters';

@mixin bottom-rounded {
  width: auto;
  height: 100%;
  left: nth($cover-rounded, 1);
  right: nth($cover-rounded, 1);
  border-bottom-left-radius: nth($cover-rounded, 2);
  border-bottom-right-radius: nth($cover-rounded, 2);
}

.cover {
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @if variable-exists(cover-color-default) {
    color: $cover-color-default;
  }

  @if variable-exists(cover-height-default) {
    height: $cover-height-default;
  }
}

@if variable-exists(cover-overlay) {
  .cover--overlay {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;

      @if type-of($cover-overlay) == color {
        background-color: $cover-overlay;
      } @else if type-of($cover-overlay) == list {
        background: linear-gradient(nth($cover-overlay, 1), nth($cover-overlay, 2));
      }
    }

    @if variable-exists(cover-rounded) {
      &.cover--rounded {
        &::before {
          @include bottom-rounded;
        }
      }
    }
  }
}

.cover__bg {
  position: absolute;
  z-index: 1;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;

  @if variable-exists(cover-rounded) {
    .cover--rounded & {
      @include bottom-rounded;
    }
  }
}

.cover__bg--parallax {
  background-attachment: fixed;
  transform: translate3d(0, 0, 0);
}

.cover__body {
  position: absolute;
  z-index: 3;
}

.cover__body--full {
  top: $cover-content-margin;
  right: $cover-content-margin;
  bottom: $cover-content-margin;
  left: $cover-content-margin;
}

@each $direction-x in (left, right, center) {
  @each $direction-y in (top, bottom, center) {
    .cover__body--#{$direction-x}-#{$direction-y} {
      $translate-x: 0;
      @if $direction-x == center {
        left: 50%;
        $translate-x: -50%;
      } @else {
        #{$direction-x}: 0;
        margin-#{$direction-x}: $cover-content-margin;
      }

      $translate-y: 0;
      @if $direction-y == center {
        top: 50%;
        $translate-y: -50%;
      } @else {
        #{$direction-y}: 0;
        margin-#{$direction-y}: $cover-content-margin;
      }

      @if $translate-x != 0 or $translate-y != 0 {
        transform: translate($translate-x, $translate-y);
      }
    }
  }
}
