$programitem-togglestar--padding: 8px;
$programitem-togglestar--margin-xs: 8px;
$programitem-togglestar--margin-sm: 12px;
$programitem-togglestar--inner-width-magic: 16px;

@function programitem-togglestar--outer-width-computed($viewport: 'xs') {
  $programitem-togglestar--margin: if($viewport == 'xs', $programitem-togglestar--margin-xs, $programitem-togglestar--margin-sm);
  @return $programitem-togglestar--inner-width-magic + (2 * $programitem-togglestar--margin) + (2 * $programitem-togglestar--padding);
}

.program-item {
  @extend %bg-color--white;
  overflow: hidden;
  border-radius: $border-radius-base;
  font-size: 14px;

  @include media-query('sm', 'mobile-first') {
    font-size: 16px;
  }

  @include when-sibling-itself {
    @extend %gutter-m-top-05x;
  }
}

.program-item__line {
  display: flex;
}

.program-item__visual {
  @extend .hidden-xs;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.program-item__visual-carousel {
  width: 216px;

  .slick-dots {
    @extend %reset-list;
    @extend %gutter-m-bottom-033x;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;

    li {
      display: inline-block;
      padding: 0 2px;
    }

    .slick-active {
      button {
        @extend %bg-color--white;
      }
    }

    button {
      @extend %reset-button;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: transparentize(pick-color('white'), 0.5);
      font-size: 0;

      &:focus {
        outline-width: 0;
      }
    }
  }
}

.program-item__carousel-control {
  @extend %color--white;
  @extend %reset-button;
  z-index: 1;
  position: absolute; /* relative parent is `.program-item__visual-carousel` */
  top: 50%;
  transform: translateY(-50%);

  &:focus {
    outline-width: 0;
  }
}

.program-item__carousel-control--prev {
  left: 5px;
}

.program-item__carousel-control--next {
  right: 5px;
}


.program-item__content {
  position: relative;
  flex-grow: 1;
  padding: 0.714285714em;

  @include media-query('sm', 'mobile-first') {
    padding: 1.25em;
  }
}

$all-themes: '';
@each $theme, $color in $program--themes {
  $all-themes: $all-themes + ', .program-item__content--' + $theme;

  .program-item__content--#{$theme} {
    border-color: $color;
  }
}

.program-item__content--wt-theme,
#{$all-themes} {
  border-left-width: 10px;
  border-left-style: solid;
}

.program-item__heading {
  @extend %gutter-m-top-0x;
  font-size: 1.125em;
  margin-bottom: 1.111111111em;
  margin-right: programitem-togglestar--outer-width-computed() - 20px;

  @include media-query('sm', 'mobile-first') {
    margin-right: programitem-togglestar--outer-width-computed('sm') - 20px;
  }
}

$prgrmtm-speakers--gutter: 10px;
$prgrmtm-speakers--gutter-sm: 8px;
$prgrmtm-speakers--gutter-xs: 6px;
.program-item__speakers {
  @extend %reset-list;
  @extend %color--orange;
  @extend %font-style--italic;
  @extend .clearfix;
  margin-bottom: 1.25em;
  margin-left: ceil(-$prgrmtm-speakers--gutter / 2);
  margin-right: floor(-$prgrmtm-speakers--gutter / 2);
}

.program-item__speakers--sm {
  font-size: 0.8em;
  letter-spacing: -0.005em;
  margin-left: ceil(-$prgrmtm-speakers--gutter-sm / 2);
  margin-right: floor(-$prgrmtm-speakers--gutter-sm / 2);
}

.program-item__speakers--xs {
  font-size: 0.7em;
  letter-spacing: -0.01em;
  margin-left: ceil(-$prgrmtm-speakers--gutter-sm / 2);
  margin-right: floor(-$prgrmtm-speakers--gutter-sm / 2);
}

.program-item__speakers-item {
  @extend .pull-left;
  padding-left: ceil($prgrmtm-speakers--gutter / 2);
  padding-right: floor($prgrmtm-speakers--gutter / 2);

  @include when-sibling-itself {
    padding-left: 0;

    &::before {
      content: '-';
      padding-right: ceil($prgrmtm-speakers--gutter / 2);
    }
  }

  .program-item__speakers--sm & {
    padding-left: ceil($prgrmtm-speakers--gutter-sm / 2);
    padding-right: floor($prgrmtm-speakers--gutter-sm / 2);

    @include when-sibling-itself {
      &::before {
        padding-right: ceil($prgrmtm-speakers--gutter-sm / 2);
      }
    }
  }

  .program-item__speakers--xs & {
    padding-left: ceil($prgrmtm-speakers--gutter-xs / 2);
    padding-right: floor($prgrmtm-speakers--gutter-xs / 2);

    @include when-sibling-itself {
      &::before {
        padding-right: ceil($prgrmtm-speakers--gutter-xs / 2);
      }
    }
  }
}

.program-item__room {
  @extend %gutter-m-bottom-0x;
  @extend %font-size--large;
}

.program-item__toggle-info {
  @extend .btn;
  @extend .btn-success;
  @extend .btn-sm;
  @extend %gutter-m-a-033x;
  position: absolute; /* relative parent is `.program-item__content` */
  bottom: 0;
  right: 0;
}

.program-item__info {
  @extend %gutter-p-a-066x;
}

.program-item__toggle-star {
  @extend %reset-button;
  position: absolute; /* relative parent is `.program-item__content` */
  top: 0;
  right: 0;
  font-size: 16px;
  padding: $programitem-togglestar--padding;
  margin: $programitem-togglestar--margin-xs;

  @include media-query('sm', 'mobile-first') {
    margin: $programitem-togglestar--margin-sm;
  }

  &:focus {
    outline-width: 0;
  }
}
