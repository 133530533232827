#user-edit-form button[type="submit"] {
  @media (min-width: $screen-md-min) {
    margin-top: 32px;
  }
}


// scss-lint:disable NestingDepth, SelectorDepth, ColorVariable
.v-filters--buttons {
  .radio {
    margin: 0;

    + .radio {
      margin-top: 20px;
    }

    > label {
      display: block;
      font-size: 16px;
      text-align: center;
      line-height: 1.1875;
      padding: 10px;
      color: #58585a;
      border: 1px solid;
      border-radius: 5px;
      margin-bottom: 0;
      transition: all 200ms ease-in-out;

      &.is-active,
      &:hover {
        color: pick-color('white');
        border-color: transparent;
        background-color: pick-color('green');
      }

      input {
        visibility: hidden;
        display: none;
      }
    }
  }
}

.v-filters--checkboxes {
  .checkbox {
    margin: 0;

    + .checkbox {
      margin-top: 20px;
    }

    > label {
      display: flex;
      padding-left: 0;
      margin: 0;
      align-items: center;

      &::before {
        content: '\f00c';
        width: $cstmchckbx--size;
        height: $cstmchckbx--size;
        border-width: $cstmchckbx--border-width;
        border-style: solid;
        border-color: $cstmchckbx--border-color;
        border-radius: 5px;
        color: transparentize($cstmchckbx--color, 1);
        margin-right: $cstmchckbx--inner-padding;
        line-height: $cstmchckbx--size - ($cstmchckbx--border-width * 2);
        text-align: center;
        user-select: none;
        font-family: fontAwesome;
        transition: color 200ms ease-in-out;
      }

      &:hover {
        &::before {
          color: transparentize($cstmchckbx--color, 0.5);
        }
      }

      &.is-active {
        &::before {
          color: transparentize($cstmchckbx--color, 0);
        }
      }

      $all-colors: '';
      @each $color in (
        '#78a225',
        '#dc5e43',
        '#f5a623',
        '#ffe817',
        '#68d3bb',
        '#8b572a',
        '#640bb1',
        '#cacaca',
        '#5f9ce3',
        '#eba668',
        '#1f6fcc',
        '#c12fa8',
        '#d42f52',
        '#b8e986',
        '#a5d14f',
        '#443d3d',
      ) {
        $all-colors: $all-colors + ', &[data-color="#{$color}"]';

        &[data-color="#{$color}"] {
          &::before {
            background-color: #{$color};
          }
        }
      }

      #{$all-colors} {
        &::before {
          border-color: transparent;
          color: transparentize(pick-color('white'), 1);
        }

        &:hover {
          &::before {
            color: transparentize(pick-color('white'), 0.5);
          }
        }

        &.is-active {
          &::before {
            color: transparentize(pick-color('white'), 0);
          }
        }
      }

      input {
        visibility: hidden;
        display: none;
      }
    }
  }
}

.login-panel {
  padding: 10px 30px 30px 30px;

  h1 {

    margin-bottom:24px;
  }
}

.label.label-xs {
  font-size: 12px;
  padding: 0.3em 0.6em;
}

.program-item__speakers .program-item__speakers-item-and-more {
  float: left;
  color: rgb(0, 0, 0);
}

.vic-widget-file.btn-block .btn {
  width: 100%;
  display: block;
}
