@function get-font-weight($name) {
    $font-weight: map-get($font-weight__list, $name);
    @return #{$font-weight};
}

@function th-font-weight--check-special($key) {
    @each $name, $value in $font-weight__special-case {
        @if $name == $key {
            @return true;
        }
    }

    @return false;
}

@function th-font-weight--value-generator($name) {
    @if th-font-weight--check-special($name) {
        @return $name;
    }

    @return get-font-weight($name);
}

@function th-font-weight--selector-line-generator($prefix, $name, $suffix: false) {
    $selector: #{$prefix}#{$typo-helper__prefix}#{$font-weight__selector-prefix}#{$name};

    @if $suffix {
        $selector: $selector + $suffix;
    }

    @return $selector;
}

@function th-font-weight--selector-generator($name, $suffix: false) {
    @return #{th-font-weight--selector-line-generator('.', $name, $suffix)}, #{th-font-weight--selector-line-generator('%', $name, $suffix)};
}
