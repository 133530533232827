/**
 * countdown / block
 * =================
 */

.countdown {
  background-color: pick-color('orange');
  border-radius: 5px;
  padding: 15px;
  text-align: center;

  @include media-query('md', 'mobile-first') {
    border-radius: 0;
    background-color: transparent;
    padding: 47px 9px 39px 42px;
    background-image: url('/bundles/app/images/trace/trace-1.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    text-align: left;
  }

  @include media-query('lg', 'mobile-first') {
    padding: 47px 59px 39px 92px;
  }
}


/**
 * remaining / element
 */
.countdown__remaining {
  @extend %gutter-m-top-0x;
  @extend %gutter-m-bottom-0x;
  @extend %font-weight--bold;
  line-height: 1.1875;
  font-size: 27px;

  @include media-query('sm', 'mobile-first') {
    font-size: 36px;
  }

  @include media-query('md', 'mobile-first') {
    font-size: 40px;
  }
}

/**
 * remaining / element
 */
.countdown__title {
  @extend %h4--xs;
  @extend %h3--sm;
  @extend %h2--md;
  @extend %gutter-m-top-0x;
  @extend %gutter-m-bottom-0x;
  @extend %font-weight--light;
}
