// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// scss-lint:disable ColorVariable NameFormat




/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;


$program--themes: (
  'adaptation': pick-color('red'),
  'agriculture': pick-color('orange'),
  'territorial-approach': pick-color('pink'),
  'cooperation': pick-color('violet'),
  'education': pick-color('green-dark'),
  'energy': pick-color('blue'),
  'forest': pick-color('green-light'),
  'lodging': pick-color('brown'),
  'transport-network': pick-color('violet-fluo'),
  'transition': pick-color('white-grey-dark'),
);


// Victoire
$victoire-header--height: 40px;
$victoire-footer--height: 50px;
$victoire-interface--height-computed: $victoire-header--height + $victoire-footer--height;

// Header
$header--height: 64px;
$header-md-plus--banner--height: 30px;
$header-md-plus--height: 70px;
$header-md-plus--height-computed: $header-md-plus--banner--height + $header-md-plus--height;


// custom-checkbox
$cstmchckbx--size: 1.785714286em;
$cstmchckbx--inner-padding: 0.357142857em;
$cstmchckbx--border-width: 0.071428571em;
$cstmchckbx--border-color: pick-color('grey');
$cstmchckbx--color: pick-color('green');
$cstmchckbx-label--color: pick-color('grey-dark');
