.btn {
  font-size: $font-size-large;
}


.btn--roundless {
  border-radius: 0;
}

/**
 * btn-default hover/active/focus has a different logic than all other btns
 */

.btn-default {
  &:hover {
    color: $btn-default-bg;
    background-color: $btn-default-color;
    border-color: $btn-default-color;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $btn-default-bg;
    background-color: $btn-default-color;
    border-color: $btn-default-color;

    &:hover,
    &:focus,
    &.focus {
      color: $btn-default-bg;
      background-color: $btn-default-color;
      border-color: $btn-default-color;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
}


.vic-widget-button {
  .btn {
    > .fa {
      @extend %gutter-m-right-033x;
    }
  }
}


.gmap-btn {
  .btn {
    border-color: transparent;
  }
}
