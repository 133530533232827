.menu {
  @include media-query('md', 'mobile-first') {
    display: inline-block;
    position: relative;
    height: 70px;
  };
}

.menu__list {
  @extend %reset-list;

  @include media-query('md', 'mobile-first') {
    display: flex;
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    padding-top: 30px * 2;
  }

  li {
    + li {
      @extend %gutter-m-top-066x;
      @extend %gutter-m-top-0x-md;
      @extend %gutter-m-left-133x-md;
    }

    a {
      color: inherit;

      // scss-lint:disable NestingDepth
      &:hover {
        text-decoration: none;

        @include media-query('md', 'mobile-first') {
          color: pick-color('orange');
        }
      }
      // scss-lint:enable NestingDepth
    }

    &.active {
      @include media-query('md', 'mobile-first') {
        color: pick-color('orange');
      }

      // scss-lint:disable NestingDepth
      a {
        cursor: default;

        @include media-query('sm', 'desktop-first') {
          text-decoration: underline;
        }
      }
      // scss-lint:enable NestingDepth
    }
  }
}

.menu__list .dropdown-menu li a {
  @include on-event {
    @include media-query('sm', 'mobile-first') {
      color: pick-color('white');
    }
  }
}

.menu__list--top {
  @extend %gutter-m-right-133x-md;

  @include media-query('md', 'mobile-first') {
    right: 100%;
  }
}

.menu__list--bottom {
  @extend %gutter-m-top-066x;
  @extend %gutter-m-top-0x-md;
  @extend %gutter-m-left-133x-md;

  @include media-query('sm', 'mobile-first') {
    left: 100%;
  }
}

.menu__logo {
  @extend .hidden-xs;
  @extend .hidden-sm;
  position: relative;
}




/**
 * menu-inline
 * ===========
 */

.menu-inline {
  @extend .list-inline;
  @extend .text-center;

  > li {
    padding-right: 0;

    &::after {
      padding-left: 5px;
      content: '-';
    }

    &:last-child {
      &::after {
        content: '';
      }
    }
  }

  a {
    @extend %color--grey-dark;

    &:hover {
      text-decoration: underline;
    }
  }
}
