// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin when-sibling-itself {
  & + & {
    @content;
  }
}


@mixin media-retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}


// scss-lint:disable IdSelector QualifyingElement
@mixin when-victoire-admin {
  body[role="admin-readonly"] &,
  body[role="admin-layout"] &,
  body[role="admin-edit"] &,
  body[role="admin-style"] &,
  #vic-admin-menu ~ #content & {
    @content;
  }
}
// scss-lint:enable IdSelector QualifyingElement


@mixin when-first-child($parent) {
  #{$parent} > &:first-child {
    @content;
  }
}

@mixin when-last-child($parent) {
  #{$parent} > &:last-child {
    @content;
  }
}
