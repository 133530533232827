@function gh-get-value($multiplier) {
    @return get-gutter($multiplier) !important;
}

@mixin gh-get-declaration($property: 'margin', $direction: '', $multiplier: 1, $breakpoint: '') {
    @if $direction == 'a' {
        #{$property}: gh-get-value($multiplier);
    } @elseif $direction == '' {
        #{$property}: gh-get-value($multiplier);
    } @elseif $direction == 'y' {
        #{$property}-top: gh-get-value($multiplier);
        #{$property}-bottom: gh-get-value($multiplier);
    } @elseif $direction == 'x' {
        #{$property}-left: gh-get-value($multiplier);
        #{$property}-right: gh-get-value($multiplier);
    } @else {
        #{$property}-#{$direction}: gh-get-value($multiplier);
    }
}
