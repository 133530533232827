.speaker-mosaic {
  @include responsive-values('display', (
    'default': block,
    'md': flex,
  ));
  flex-direction: row;
  overflow: hidden;
  position: relative;

  > * {
    flex-basis: 50%;
    flex-shrink: 0;
  }
}

.speaker-mosaic__eventTypes-wrapper {
  display: flex;
  width: 100%;

  @include media-query('sm', 'desktop-first') {
    @include when-inside('.is-grid') {
      display: none;
    }

    @include when-inside('.is-details') {
      display: none;
    }
  }
}

.speaker-mosaic__eventTypes {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include when-tag('ul') {
    @include reset-list;
  }
}

.speaker-mosaic__eventTypes-item {
  @include responsive-values('height', (
          'default': 160px,
          'md': 240px,
  ));
}

.speaker-mosaic__eventTypes-anchor {
  @include responsive-values('padding', (
    'default': 0 20px,
    'md': 0 50px,
  ));

  @include responsive-values('height', (
    'default': 160px,
    'md': 100%,
  ));

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: pick-color('white');
  cursor: pointer;
  background-size: cover;

  @include media-query('md', 'mobile-first') {
    opacity: 0.3;
    transition: opacity 250ms ease-in-out;
  }

  @include on-event {
    color: pick-color('white');
    text-decoration: none;

    @include media-query('md', 'mobile-first') {
      opacity: 1;
    }
  }

  &.is-in {
    @include media-query('md', 'mobile-first') {
      opacity: 1;
    }
  }
}

.speaker-mosaic__eventTypes-heading {
  font-weight: get-font-weight('light');
  font-size: 36px;
  line-height: 1.222222222;
  margin-bottom: 9px;
}

.speaker-mosaic__eventTypes-description {}

.speaker-mosaic__grid-wrapper {
  @include media-query('sm', 'desktop-first') {
    display: none;

    @include when-inside('.is-grid') {
      display: block;
    }

    @include when-inside('.is-details') {
      display: none;
    }
  }
}

.speaker-mosaic__grid {
  display: none;
  flex-wrap: wrap;
  align-items: flex-start;

  @include when-tag('ul') {
    @include reset-list;
  }

  &.is-in {
    display: flex;
  }
}

.speaker-mosaic__grid-item {
  @include responsive-values('flex-basis', (
    'default': percentage(1 / 2),
    'sm': percentage(1 / 3),
    'md': percentage(1 / 4),
  ));
}

.speaker-mosaic__grid-anchors {
  @include aspect-ratio(1);
  position: relative;
  cursor: pointer;
  background-color: pick-color('orange');
  overflow: hidden;

  @include when-tag('a') {
    display: block;
  }
}

.speaker-mosaic__grid-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 300ms ease-in-out;

  @include when-inside('.speaker-mosaic__grid-anchors:hover') {
    opacity: 0.5;
  }

  @include when-inside('.speaker-mosaic__grid-anchors.is-in') {
    opacity: 0.5;
  }
}


.speaker-mosaic__details-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  left: -50%;
  width: 50%;

  @include media-query('sm', 'desktop-first') {
    position: static;
    display: none;
    width: 100%;

    @include when-inside('.is-details') {
      display: block;
    }
  }
}

.speaker-mosaic__detail {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: pick-color('white');
  transition: transform 300ms ease-in-out;
  display: flex;
  flex-direction: column;

  @include media-query('sm', 'desktop-first') {
    display: none;
    position: static;
  }

  &.is-in {
    @include media-query('md', 'mobile-first') {
      transform: translateX(100%);
    }

    @include when-inside('.is-details') {
      display: flex;
    }
  }
}

.speaker-mosaic__detail-header {
  flex-basis: 360px;
  flex-shrink: 0;
  position: relative;
  background-color: pick-color('orange');
  color: pick-color('white');
}

.speaker-mosaic__detail-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}


.speaker-mosaic__detail-title {
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  bottom: 23px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: get-font-weight('light');
}

.speaker-mosaic__detail-body {
  flex-grow: 1;
  overflow-y: auto;
  max-width: 500px;
  padding: 15px;
  margin: auto;
  position: relative;
}

.speaker-mosaic__detail-close {
  @include responsive-values('margin', (
    'default': 15px,
    'md': 35px,
  ));
  @include responsive-values('left', (
    'default': 0,
    'md': auto,
  ));
  @include responsive-values('right', (
    'default': auto,
    'md': 0,
  ));
  position: absolute;
  z-index: 1;
  top: 0;
  color: currentColor;
  cursor: pointer;
}

.breadcrumb {
  @include when-inside('.speaker-mosaic') {
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 0;
    display: none;

    @include media-query('sm', 'desktop-first') {
      &.is-in {
        display: block;
      }
    }

    a {
      cursor: pointer;
    }
  }
}
