.col-xs-5ths {
  @extend .col-xs-12;
}

.col-sm-5ths {
  @extend .col-sm-6;

  @include when-last-child('.row') {
    @extend .col-sm-12;
  }
}

.col-md-5ths,
.col-lg-5ths {
  @extend .col-md-4;

  @include media-query('md', 'mobile-first') {
    width: (100% / 5);
  }

  @include when-last-child('.row') {
    @include media-query('md', 'mobile-first') {
      width: (100% / 5);
    }
  }
}
