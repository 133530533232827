.thumbnail {
  padding: 0;
  margin: 15px auto;
  line-height: inherit;
  background-color: transparent;
  border: 1px solid #ddd;
  transition: none;
}

.thumbnail__body {

  @include media-query('xs', 'restrictive') {
    min-height: 110px;
  }

  @include media-query('sm', 'mobile-first') {
    min-height: 110px;

  }

  @include media-query('md', 'mobile-first') {
    min-height: 150px;
  }

  @include media-query('lg', 'mobile-first') {
    min-height: 130px;
  }

}

// scss-lint:disable QualifyingElement
a.thumbnail {
  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $tbnl--border-color;
    color: inherit;
    text-decoration: none;
  }
}
// scss-lint:enable QualifyingElement


.thumbnail__img {
  background-position: center;
}
