.map-popover {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}

.map-popover__header {
  @extend %color--grey-dark;
  @extend %bg-color--white-grey;
  padding: 10px;
}

.map-popover__row {
  @include clearfix;
}

.map-popover__img {
  @include aspect-ratio(1);
  float: left;
  background-size: cover;
  background-position: center;
  width: (100% / 3) * 1;
}

// scss-lint:disable ImportantRule
.map-popover__paragraph {
  float: left;
  padding: 10px;
  width: (100% / 3) * 2;
}
// scss-lint:enable ImportantRule

// scss-lint:disable ImportantRule
.map-popover__description {
  @extend %gutter-m-y-0x;
  line-height: 1.214285714 !important;
}
// scss-lint:enable ImportantRule

.map-popover__tags {
  @extend .list-inline;
  margin-bottom: 0;
  padding: 10px 10px 0;

  > * {
    padding-bottom: 10px;
  }
}

.map-popover__anchor {
  @extend %gutter-m-right-033x;
  @extend %gutter-m-top-025x;
  @extend %color--green;
  display: inline-block;
}
