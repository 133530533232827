.tab-buttons {
  display: flex;
  justify-content: center;
}

$tbbttns-anchor--padding-x: 20px;
$tbbttns-anchor--padding-y: 10px;
$tbbttns-anchor--border-width: 1px;
$tbbttns--border-radius: 4px;

.tab-buttons__anchor {
  width: 150px;
  padding: $tbbttns-anchor--padding-y $tbbttns-anchor--padding-x;
  border-width: $tbbttns-anchor--border-width;
  border-style: solid;
  border-color: pick-color('grey-light');
  text-align: center;
  color: inherit;

  @include when-first-child('.tab-buttons') {
    border-radius: $tbbttns--border-radius 0 0 $tbbttns--border-radius;
  }

  @include when-last-child('.tab-buttons') {
    border-radius: 0 $tbbttns--border-radius $tbbttns--border-radius 0;
  }

  &:not(.is-active) + & {
    border-left-color: transparent;
  }

  // scss-lint:disable ColorVariable
  &.is-active {
    padding: ($tbbttns-anchor--padding-y + $tbbttns-anchor--border-width) ($tbbttns-anchor--padding-x + $tbbttns-anchor--border-width);
    background-color: pick-color('white-grey');
    border-width: 0;
    box-shadow: inset 1px 1px 5px 0 rgba(51, 51, 51, 0.25);
    cursor: default;

    &:focus,
    &:hover {
      color: inherit;
    }
  }
  // scss-lint:enable ColorVariable
}
