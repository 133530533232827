// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}
