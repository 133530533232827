/**
 * program-panel
 * =============
 */

.program-panel {
  @extend %gutter-p-a-05x;
  @extend %gutter-m-bottom-066x;
  @extend %bg-color--white-grey;
}


/**
 * program-panel__heading
 * ----------------------
 */

.program-panel__heading {
  @extend %h4--xs;
  @extend %h3--sm;
  @extend %font-weight--light;
  @extend %gutter-m-top-0x;
  @extend %gutter-m-bottom-05x;
}
