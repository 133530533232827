// scss-lint:disable NestingDepth
.breadcrumb {
  border-radius: 0;

  > li {
    + .active {
      &::before {
        color: $breadcrumb-active-color;
      }
    }
  }
}
// scss-lint:enable NestingDepth
