.localeSwitcher {
  @extend %text-transform--uppercase;

  &.open {
    .dropdown-toggle {
      box-shadow: none;
    }
  }

  .dropdown-toggle {
    @extend %color--green-hover;
    border-color: transparent;
    background-color: transparent;
    color: inherit;
    text-transform: inherit;
    border-radius: 0;

    &:active {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    min-width: 100px;
  }
}
