%reset-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

%reset-button {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}
