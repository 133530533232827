// $hdngtrc--paddings: (
//   1: (
//     'width': 560px,
//     'height': 198px,
//     'top': 2.02020202%,
//     'right': 13.214285714%,
//     'bottom': 14.646464646%,
//     'left': 19.107142857%,
//   ),
//   2: (
//     'width': 610px,
//     'height': 263px,
//     'top': 26.615969582%,
//     'right': 8.196721311%,
//     'bottom': 6.463878327%,
//     'left': 8.360655738%,
//   ),
//   3: (
//     'width': 717px,
//     'height': 198px,
//     'top': 29.292929293%,
//     'right': 8.089260809%,
//     'bottom': 26.262626263%,
//     'left': 13.668061367%,
//   ),
//   4: (
//     'width': 636px,
//     'height': 198px,
//     'top': 30px,
//     'right': 71px,
//     'bottom': 36px,
//     'left': 72px,
//   ),
//   5: (
//     'width': 561px,
//     'height': 198px,
//     'top': 50px,
//     'right': 51px,
//     'bottom': 50px,
//     'left': 98px,
//   ),
// );


$hdngtrc--paddings: (
  1: (
    'width': 560px,
    'height': 198px,
    'top': get-percentage(4px, 198px),
    'right': get-percentage(74px, 560px),
    'bottom': get-percentage(29px, 198px),
    'left': get-percentage(107px, 560px),
  ),
  2: (
    'width': 610px,
    'height': 263px,
    'top': get-percentage(70px, 263px),
    'right': get-percentage(50px, 610px),
    'bottom': get-percentage(17px, 263px),
    'left': get-percentage(51px, 610px),
  ),
  3: (
    'width': 717px,
    'height': 198px,
    'top': get-percentage(58px, 198px),
    'right': get-percentage(58px, 717px),
    'bottom': get-percentage(52px, 198px),
    'left': get-percentage(98px, 717px),
  ),
  4: (
    'width': 636px,
    'height': 198px,
    'top': get-percentage(30px, 198px),
    'right': get-percentage(71px, 636px),
    'bottom': get-percentage(36px, 198px),
    'left': get-percentage(72px, 636px),
  ),
  5: (
    'width': 561px,
    'height': 198px,
    'top': get-percentage(50px, 198px),
    'right': get-percentage(51px, 561px),
    'bottom': get-percentage(50px, 198px),
    'left': get-percentage(98px, 561px),
  ),
);

.heading-trace {
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@each $trace, $declarations in $hdngtrc--paddings {
  .heading-trace--#{$trace} {
    @include aspect-ratio(map-get($declarations, 'width'), map-get($declarations, 'height'));
    background-image: url('/bundles/app/images/trace/trace-#{$trace}.png');
    max-width: map-get($declarations, 'width');
    width: 100%;

    @include media-retina {
      background-image: url('/bundles/app/images/trace/trace-#{$trace}@2x.png');
    }

    .heading-trace__label-wrapper {
      top: map-get($declarations, 'top');
      right: map-get($declarations, 'right');
      bottom: map-get($declarations, 'bottom');
      left: map-get($declarations, 'left');
    }
  }
}

.heading-trace__label-wrapper {
  display: block;
  position: absolute; /* relative parent is `.heading-trace` */
}

.heading-trace__label {
  @extend %h3--xs;
  @extend %h2--sm;
  @extend %h1--md;
  @extend %color--white;
  @extend %gutter-m-y-0x;
  @extend %font-weight--light;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
