.square {
  @extend %gutter-m-bottom;
  @include aspect-ratio(1);
  position: relative;
  max-width: 200px;
  margin: auto;
}

.square__content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}
