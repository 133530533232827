.accordion__anchor {
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.accordion__toggle-icon {
  display: none;
}

.accordion__toggle {
  &::before {
    @extend %color--white;
    content: '\f107';
    font-family: fontAwesome;
  }
}

.accordion__content {
  overflow-y: hidden;
}
