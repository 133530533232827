// Usefull function to get gutter values without any extend or class
// implementation.

@function get-gutter($multiplier: 1) {
    /// If multiplier params is not a number
    @if type-of($multiplier) != number {
        @return null;
    }

    /// If multiplier is not a part of $gh-multipliers map
    @if not index($gh-multipliers, $multiplier) {
        @return null;
    }

    $value: $gh-gutter * $multiplier;
    @if gh-do-round($value) {
        $value: round($value);
    }
    @return $value;
}
