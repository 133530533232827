label {
  @extend %gutter-m-bottom-033x;
  @extend %font-weight--normal;
}

.form-control {
  @extend %gutter-p-a-033x;
  font-size: $font-size-large;
  line-height: 1.1875;
  box-shadow: inset 0 1px 5px 0 transparentize(pick-color('black'), 0.85);
}
