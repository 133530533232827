$header--z-index: $zindex-tooltip + 10; // $zindex-tooltip is the highest bootstrap's component z-index.

%header__sub-wrapper {
  position: fixed;
  backface-visibility: hidden;
  top: 0;
  width: 100%;

  @include media-query('md', 'mobile-first') {
    position: static;
    backface-visibility: visible;
    width: auto;
  }
}

@mixin when-victoire-login {
  .victoire ~ .header & {
    @content;
  }
}

.header {
  @include media-query('md', 'mobile-first') {
    position: fixed;
    backface-visibility: hidden;
    background-color: pick-color('white');
    width: 100%;
    top: 0;
    left: 0;
    height: $header-md-plus--height-computed;
    box-shadow: 0 2px 10px 0 transparentize(pick-color('black'), 0.9);
    z-index: 1;
  }

  .victoire ~ & {
    @include media-query('md', 'mobile-first') {
      top: 40px;
    }
  }

  &::before {
    @include media-query('md', 'mobile-first') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: $header-md-plus--banner--height;
      width: 100%;
      background-color: pick-color('white-grey');
    }
  }
}


.header__hide-wrapper {
  $transition-duration: 300ms;
  @extend %header__sub-wrapper;
  @extend %gutter-p-y;
  @extend %gutter-p-y-0x-md;
  @extend .text-center;
  @extend %h4--xs;
  @extend %h5--md;
  z-index: $header--z-index + 1;
  color: pick-color('white');
  background-image: url('/bundles/app/images/gui/header/hide-wrapper.jpg');
  background-size: cover;
  background-position: center;
  fill: pick-color('white');
  stroke: pick-color('white');
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-duration: 0ms, $transition-duration;
  transition-delay: $transition-duration, 0ms;
  transition-timing-function: ease;
  overflow-y: auto;

  @include media-query('md', 'mobile-first') {
    background-image: none;
    color: pick-color('grey-dark');
    background-color: transparent;
    visibility: visible;
    opacity: 1;
    height: auto;
    height: 100%;
    overflow-y: visible;
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms, 0ms;
  }
}

.header__hide-toggle {
  @extend %reset-button;
  @extend %gutter-m-bottom-133x;

  &:focus {
    outline-width: 0;
  }
}

.header__nav {
  @extend %gutter-m-bottom-2x;
  @extend %gutter-m-bottom-0x-md;
}

.header__visible-wrapper {
  @extend %header__sub-wrapper;
  @extend %gutter-p-x-05x;
  @extend %gutter-p-a-0x-md;
  background-color: pick-color('white');
  color: pick-color('grey-dark');
  height: $header--height;
  z-index: $header--z-index;
  box-shadow: 0 2px 4px 0 transparentize(pick-color('black'), 0.85);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include when-victoire-login {
    top: 40px;
    z-index: 1000;
  }

  @include media-query('md', 'mobile-first') {
    background-color: transparent;
    height: auto;
    box-shadow: none;
  }
}

[data-toggle="header__hide-wrapper"],
.header__sm-logo {
  @extend .hidden-md;
  @extend .hidden-lg;
}

.header__sm-logo {
  position: absolute; /* relative parent is `.header__visible-wrapper` */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header__visible-toggle {
  @extend %reset-button;
  font-size: 0;

  &:focus {
    outline-width: 0;
  }
}

.header__lang-picker {
  @include media-query('md', 'mobile-first') {
    position: absolute;
    top: 0;
    right: get-gutter();
  }
}

.header__log {
  @include media-query('md', 'mobile-first') {
    position: absolute;
    top: 0;
    right: 120px;
  }

  > * {
    @include media-query('md', 'mobile-first') {
      float: left;
    }

    + * {
      @extend %gutter-m-top-066x;
      @extend %gutter-m-top-0x-md;
      @extend %gutter-m-left-133x-md;
    }

  }

  .btn {
    background-color: transparent;
    color: inherit;
    border-color: transparent;
    font-size: inherit;
    padding: 0;
    line-height: inherit;

    @include media-query('md', 'mobile-first') {
      line-height: $header-md-plus--banner--height - 2px;
    }

    @include on-event {
      @include media-query('md', 'mobile-first') {
        color: pick-color('orange');
        border-color: transparent;
        background-color: transparent;
      }
    }

    &:active {
      box-shadow: none;
    }

    &:focus {
      outline-width: 0;
    }
  }
}

#content {
  padding-top: $header--height;
  position: relative;
  z-index: 0;

  @include media-query('md', 'mobile-first') {
    padding-top: $header-md-plus--height-computed;
  }
}

.header__nav {
  @include media-query('md', 'mobile-first') {
    height: 100%;
  }
}
