$frmt--height: 160px;
$frmt--height-sm: 250px;
$frmt-slider--height: 300px;
$frmt-slider--width: 250px;

.format {
  @extend .clearfix;
  height: $frmt--height;
  background-size: cover;
  background-position: center;
  position: relative;

  @include media-query('sm', 'mobile-first') {
    margin-top: (($frmt-slider--height - $frmt--height-sm) / 2);
    height: $frmt--height-sm;
  }

  @include when-sibling-itself {
    @include media-query('sm', 'mobile-first') {
      margin-top: ($frmt-slider--height - $frmt--height-sm) + 150px;
    }
  }
}

.format__container {
  @extend %gutter-m-top-2x-sm;
  @extend %color--white;
  max-width: 250px;
  padding-left: floor(($grid-gutter-width / 2));
  padding-right: ceil(($grid-gutter-width / 2));

  @include media-query('xs', 'restrictive') {
    position: absolute; /* relative parent is `.format` */
    top: 50%;
    transform: translateY(-50%);
  }

  @include media-query('sm', 'mobile-first') {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $container-sm;
    padding-right: $frmt-slider--width + $grid-gutter-width;
  }

  @include media-query('md', 'mobile-first') {
    max-width: $container-md;
  }

  @include media-query('lg', 'mobile-first') {
    max-width: $container-lg;
  }
}

.format__heading {
  @extend %gutter-m-top-0x;
  @extend %gutter-m-bottom-025x;
  @extend %font-weight--light;
}

.format__paragraph {
  @extend %gutter-m-bottom-0x;
}

.format__slider {
  @extend %gutter-m-right-05x;
  @extend .hidden-xs;
  width: $frmt-slider--width;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: ($frmt--height-sm - $frmt-slider--height) / 2;

  .slick-list {
    @extend .img-rounded;
  }
}

.format__slider-item {
  position: relative;
}

.format__slider-label {
  @extend .text-center;
  @extend %gutter-p-a-033x;
  position: absolute; /* relative parent is `.format__slider-item` */
  bottom: 0;
  width: 100%;
  left: 0;
  font-size: 20px;
}

.format__slider-nav {
  @extend %reset-button;
  @extend %gutter-p-a-033x;
  position: absolute; /* relative parent is `.format__slider` */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &:focus {
    outline-width: 0;
  }
}

.format__slider-nav--prev {
  left: 0;
}

.format__slider-nav--next {
  right: 0;
}
