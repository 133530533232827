@charset 'UTF-8';

/**
 * Configuration and helpers
 */
@import './vendor/responsive-helper'; @import '../public/bower_components/silverhold-responsive/responsive-helper';
@import './vendor/color-helper'; @import '../public/bower_components/silverhold-color/color-helper';
@import 'utils/variables';

/* styleutils:scss */
/* endinject */

@import 'utils/placeholders';
@import 'utils/functions';
@import 'utils/mixins';
// END utils

/**
 * Vendors
 */
@import '../public/bower_components/silverhold-bucket/bucket';
@import './vendor/gutter-helper'; @import '../public/bower_components/silverhold-gutter/gutter-helper';
@import './vendor/typo-helper'; @import '../public/bower_components/silverhold-typo/typo-helper';
@import './vendor/heading-helper'; @import '../public/bower_components/silverhold-heading/heading-helper';
@import './vendor/bootstrap'; @import '../public/bower_components/bootstrap-sass/assets/stylesheets/bootstrap';
@import './vendor/thumbnail'; @import '../public/bower_components/silverhold-thumbnail/thumbnail';
@import './vendor/thumbnail'; @import '../public/bower_components/silverhold-cover/cover';
@import './vendor/font-awesome'; @import '../public/bower_components/font-awesome/scss/font-awesome';
@import './vendor/accordion'; @import '../public/bower_components/silverhold-accordion/accordion';
// scss-lint:disable ImportPath
@import '../public/bower_components/slick-carousel/slick/slick.scss';
// scss-lint:enable ImportPath

/* stylevendors:scss */
/* endinject */

/* stylevendors:css */
/* endinject */
// END vendors

/**
 * Base stuff
 */
@import 'base/basic';
@import 'base/fonts';
@import 'base/typography';
@import 'base/helpers';
// END base

/**
 * Components
 */

// Atoms
@import './components/bootstrap-button';
@import './components/bootstrap-form';
@import './components/bootstrap-panel';
@import './components/bootstrap-label';
@import './components/bootstrap-dropdown';
@import './components/custom-checkbox';
@import './components/square';
@import './components/victoire-social-button';
@import './components/victoire-locale-switcher';
@import './components/victoire-menu';
@import './components/victoire-image';
@import './components/victoire-cover';
@import './components/victoire-title';
@import './components/victoire-layout';
@import './components/victoire-countdown';
@import './components/victoire-tab';
@import './components/victoire-form';
@import './components/heading-trace';

@import './components/thumbnail';
@import './components/bootstrap-breadcrumb';
@import './components/program-panel';
@import './components/program-item';
@import './components/format';
@import './components/map';
@import './components/map-popover';
@import './components/map-filters';
@import './components/blog-thumbnail';
@import './components/speaker-mosaic';
// END components

/**
 * Layout-related sections
 */
@import './layout/header';
@import './layout/footer';
// END layout

/**
 * Page-specific styles
 */
// END pages

/**
 * Themes
 */
// END themes

/**
 * Shame
 */
@import 'shame';
