.panel {
  margin-bottom: 0;
  border-color: pick-color('grey-light');
  box-shadow: 0 1px 6px 0 transparentize(pick-color('black'), 0.9);
}

.panel--opacity {
  border-color: transparent;
  background-color: transparentize(pick-color('white'), 0.1);
  box-shadow: none;
}
