$text-decoration__special-case: (
    inherit: $text-decoration__include--inherit
);

@mixin text-decoration--statement-generator($name) {
    #{th-text-decoration--selector-generator($name)} {
        text-decoration: $name !important;
    }

    @each $pseudo-class, $bool, $suffix in $text-decoration__pseudo-class {
        @if $bool {
            #{th-text-decoration--selector-generator($name, $suffix)} {
                #{$pseudo-class} {
                    text-decoration: $name !important;
                }
            }
        }
    }
}

@mixin text-decoration--extend($name) {
    @extend #{th-text-decoration--selector-line-generator('%', $name)};
}
