%map-filters__index {
  z-index: 1001; // Magic number to be bigger than the `.leaflet-bottom, .leaflet-top` z-index
}

/**
 * map-filters
 * ===========
 */

.map-filters {
  font: $font-size-base $font-family-base;
  font-family: $font-family-base;
}


/**
 * map-filters__toggle
 * -------------------
 */

.map-filters__toggle {
  @extend .btn;
  @extend .btn-success;
  @extend .btn-block;
  @extend .hidden-md;
  @extend .hidden-lg;
  @extend %map-filters__index;
  border-radius: 0;
  position: absolute; /* relative parent is `.map` */
  left: 0;
  bottom: 0;
}


/**
 * map-filters__breadcrumb
 * -----------------------
 */

.map-filters__breadcrumb {
  @extend .breadcrumb;
  @extend .hidden-md;
  @extend .hidden-lg;
}


/**
 * map-filters__touch-panel
 * ------------------------
 */

.map-filters__touch-panel {
  @extend %map-filters__index;
  @extend %bg-color--white;
  position: absolute; /* relative parent is `.map` */
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  will-change: transform;
  transition: transform 500ms ease;
  overflow-y: auto;

  @include media-query('md', 'mobile-first') {
    position: static;
    transform: none;
  }

  &.is-in {
    transform: translateY(0%);
  }
}


/**
 * map-filters__heading
 * --------------------
 */

.map-filters__heading {
  @extend %h3--xs;
  @extend %gutter-m-x-033x;
  @extend %gutter-m-top-066x;
  @extend %gutter-m-bottom;
  @extend %font-weight--light;
  @extend .hidden-md;
  @extend .hidden-lg;
}


/**
 * map-filters__list
 * -----------------
 */

.map-filters__list {
  @extend %reset-list;
  @extend %gutter-m-top-md;
  @extend %gutter-m-bottom-033x;
  @extend %gutter-m-bottom-066x-md;
  @extend .clearfix;

  @include media-query('md', 'mobile-first') {
    text-align: center;
  }
}


/**
 * map-filters__list-item
 * ----------------------
 */

.map-filters__list-item {
  @extend %gutter-p-x-033x;
  @extend %gutter-m-bottom-033x-md;

  @include media-query('md', 'mobile-first') {
    display: inline-block;
  }

  @include when-sibling-itself {
    @extend %gutter-m-top-033x;
    @extend %gutter-m-top-0x-md;
    @extend %gutter-p-top-033x;
    @extend %gutter-p-top-0x-md;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: pick-color('grey-light');

    @include media-query('md', 'mobile-first') {
      border-top-width: 0;
    }
  }
}


/**
 * map-filters__anchor
 * -------------------
 */

.map-filters__anchor {
  @extend .label;

  @include media-query('sm', 'desktop-first') {
    width: 100%;
    text-align: left;
    padding-top: 0.93em;
    padding-bottom: 0.93em;
  }
}

@each $theme, $color in $program--themes {
  .map-filters__anchor--#{$theme} {
    @extend .label--#{$theme};
  }
}
