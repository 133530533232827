.vic-widget-image {
  max-width: 100%;

  img {
    max-width: 100%;
  }
}


/**
 * circled-img - block - victoire theme
 * ====================================
 */

.circled-img {
  border-radius: 50%;
  box-shadow: none;
  border: 1px solid pick-color('grey-light');
  box-shadow: none;
  transition: box-shadow 300ms ease;

  a:hover > & {
    box-shadow: 0 1px 6px 0 transparentize(pick-color('black'), 0.9);
  }
}
