$vicsclcntnr--size: 40px;

.social-container {
  @extend %reset-list;
  @extend %gutter-m-x--033x;
  @extend %gutter-m-x--066x-sm;
  font-size: 0;
  text-align: center;

  li {
    @extend %gutter-p-x-033x;
    @extend %gutter-p-x-066x-sm;
    display: inline-block;
  }

  a {
    @extend %color--white;
    font-size: 20px;
    line-height: $vicsclcntnr--size;
    display: inline-block;
    width: $vicsclcntnr--size;
    height: $vicsclcntnr--size;
    border-radius: $vicsclcntnr--size;
  }
}

@each $social in ('facebook', 'twitter', 'periscope', 'linkedin', 'slideshare') {
  .social-icon-#{$social} {
    @extend %bg-color--#{$social};
    @extend %bg-color--#{$social}-dark-hover;
  }
}
