$heading__all--block: (
  'line-height': 1.222222,
  'font-weight': 300,
);

$heading--defintions: (
  1: (
    'font-size': 36px,
  ),
  2: (
    'font-size': 30px,
  ),
  3: (
    'font-size': 24px,
  ),
  4: (
    'font-size': 18px,
  ),
  5: (
    'font-size': 14px,
  ),
  6: (
    'font-size': 12px,
  ),
);
