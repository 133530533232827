/**
 * footer
 * ======
 */

.footer {
  @extend .clearfix;

  @include media-query('md', 'mobile-first') {
    background-image: url('/bundles/app/images/gui/footer/footer_left@2x.png'), url('/bundles/app/images/gui/footer/footer_right@2x.png');
    background-repeat: no-repeat;
    background-position: bottom left, bottom right;
    background-size: auto 100%, auto 54%;
  }
}


/**
 * footer__container
 * -----------------
 */

.footer__container {
  @extend %gutter-m-top-15x;
  @extend %gutter-m-bottom-05x;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 440px;
}


/**
 * footer__social
 * --------------
 */

.footer__social {
  @extend %gutter-m-bottom;
  @extend %gutter-m-bottom-133x-md;
  @extend .text-center;
}


/**
 * footer__row
 * -----------
 */

.footer__row {
  @extend %gutter-m-bottom-066x;
  @extend %gutter-m-bottom-133x-md;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-query('md', 'mobile-first') {
    flex-direction: row;
    justify-content: center;
  }
}

/**
* footer__logo
* ------------
*/

.footer__logo {
  @extend %gutter-m-bottom-05x;
  @extend %gutter-m-bottom-0x-md;
  @extend %gutter-m-right-166x-md;
  max-width: 154px;
}


/**
 * footer__partnership
 * -------------------
 */

.footer__partnership {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__partnership-item {
  @include when-sibling-itself {
    @extend %gutter-m-left-166x;
  }
}


/**
 * footer__btn
 * -----------
 */

.footer__btn {
  @extend %gutter-m-bottom-066x;
  @extend %gutter-m-bottom-133x-md;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 320px;
}
